import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/breadcrumb'
import IndexPage from '../components/indexPage'

const SeoPage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/efficacite-seo" type="index">
      <SEO 
        title="Meilleurs sites en référencement : le classement SEO"
        description="	Quels sont les meilleurs sites en SEO de l'Observatoire de l'impact positif ? Découvrez les sites qui utilisent le mieux les critères SEO pour se démarquer."
        path="/efficacite-seo" />
      
      <Breadcrumb url="/efficacite-seo" label="Efficacité SEO" /> 

      <h1 className="sr-only">Efficacité SEO</h1>
      <IndexPage slug="seo" type="index" />
    </Layout>
  )
  }

export default SeoPage

